class Accordion {
  constructor() {
    this.accordions = document.querySelectorAll(".accordion");
    if (this.accordions.length > 0) {
      this.init();
    }
  }

  init() {
    this.accordions.forEach(accordion => {
      accordion.addEventListener("click", (ev) => {
        if (ev.target.classList.contains("accordion__question")) {
          const openItem = accordion.querySelector('.accordion__question--toggled')
          if (openItem && openItem != ev.target) {
            openItem.classList.remove('accordion__question--toggled')
          }

          let button = ev.target;
          button.classList.toggle('accordion__question--toggled')
        }
      });
    })
  }
}

export default Accordion;